import React from 'react'
import DarkLearnMore from '../../components/DarkLearnMore'
import DarkNavbar from '../../components/DarkNavbar'
import Footer from '../../components/Footer'

export default function Team() {
  return (
    <div className="team">
      <DarkNavbar/>
     <div className="main-container">
      <div className="wrapper">
        <div className="title">
          <div>Towards a <span>Better Tomorrow</span></div>
        </div>
        <div className="text">
        We are creating a dynamic culture of engineers who are eager to deliver
        </div>
      </div>
    </div>
    <div className="intro">
    {/* <div className="title"><span>Learn more</span> about our solutions</div> */}
      <div className="title">Meet the team</div>
      <div className="text">
         Advancing the industrial success with our team of innovators.
      </div>
      
      <div className="item-wrapper">
        <div>
          <div className="item">
          <div className="sub-title">Executives</div>
            <div className="item-inner">
              <img src={require("../../assets/image/image 66.png")} alt="" />
              <img src={require("../../assets/image/Ellipse 11.png")} alt="" className="circle" />
              <img src={require("../../assets/image/Ellipse 12.png")} alt="" className="circle" />
            </div>
          </div>
          <div className="item-text">
            <div>Erdenetuya</div>
            <div>Chuluunbaatar</div>
            <div>CEO</div>
          </div>
        </div>
        <div>
          <div className="item">
            <div className="item-inner">
            <img src={require("../../assets/image/image 67.png")} alt="" className='pt-2'/>
              <img src={require("../../assets/image/Ellipse 11.png")} alt="" className="circle" />
              <img src={require("../../assets/image/Ellipse 12.png")} alt="" className="circle" />
            </div>
          </div>
          <div className="item-text">
            <div>Anar</div>
            <div>Chinbaatar</div>
            <div>Founder</div>
          </div>
        </div>
        <div> 
          <div className="item">
            <div className="item-inner">
            <img src={require("../../assets/image/tulgaa.png")} alt="" className='pt-3'/>
              <img src={require("../../assets/image/Ellipse 11.png")} alt="" className="circle" />
              <img src={require("../../assets/image/Ellipse 12.png")} alt="" className="circle" />
            </div>
          </div>
          <div className="item-text">
            <div>Battulga</div>
            <div>Bayarsaikhan </div>
            <div>Founder</div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="item-wrapper">
        <div>
          <div className="item">
          <div className="sub-title">Leadership</div>
            <div className="item-inner">
            <img src={require("../../assets/image/image 72.png")} alt="" />
              <img src={require("../../assets/image/Ellipse 11.png")} alt="" className="circle" />
              <img src={require("../../assets/image/Ellipse 12.png")} alt="" className="circle" />
            </div>
          </div>
          <div className="item-text">
            <div>Mungun</div>
            <div>Davaa</div>
            <div>Software Lead</div>
          </div>
        </div>
        <div>
          <div className="item">
            <div className="item-inner">
            <img src={require("../../assets/image/image 69.png")} alt="" />
              <img src={require("../../assets/image/Ellipse 11.png")} alt="" className="circle" />
              <img src={require("../../assets/image/Ellipse 12.png")} alt="" className="circle" />
            </div>
          </div>
          <div className="item-text">
            <div>Amartuvshin</div>
            <div>Dagvasumberel</div>
            <div>Hardware Lead</div>
          </div>
        </div>
        <div>
          <div className="item">
            <div className="item-inner">
            <img src={require("../../assets/image/image 70.png")} alt="" />
              <img src={require("../../assets/image/Ellipse 11.png")} alt="" className="circle" />
              <img src={require("../../assets/image/Ellipse 12.png")} alt="" className="circle" />
            </div>
          </div>
          <div className="item-text">
            <div>Murun</div>
            <div>Enkhee</div>
            <div>Engineering Manager</div>
          </div>
        </div>
        <div>
          <div className="item">
            <div className="item-inner">
            <img src={require("../../assets/image/image 73.png")} alt="" />
              <img src={require("../../assets/image/Ellipse 11.png")} alt="" className="circle" />
              <img src={require("../../assets/image/Ellipse 12.png")} alt="" className="circle" />
            </div>
          </div>
          <div className="item-text">
            <div>Byambajargal</div>
            <div>Naranbat</div>
            <div>Engineering Manager</div>
          </div>
        </div>
      </div>
      <div className="bg"></div>
    </div>
    <DarkLearnMore/>
    <Footer/>
  </div>

  )
}
