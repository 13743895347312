import React from 'react'
import DarklearnMore from '../../components/DarkLearnMore'
import Footer from '../../components/Footer'
import LightNavbar from '../../components/LigthNavbar'
// import DarkNavbar from '../../components/DarkNavbar'

export default function Mission() {
  return (
    <div className="mission">
      <LightNavbar/>
     <div className="main-container">
       <div className="row">
        <div className="col-md-6 col-12 d-flex flex-column justify-content-center">
          <div className="title">
            <span>Bringing next wave of digital transformation</span> which
            accelerates the growth of industries.
          </div>
          <div className="text">
            We develop a digital twin technology which creates a digital
            representation of a mine site along with its physical environment,
            objects, and process, which capable to demonstrate 4D and 5D
            visualizations.
          </div>
        </div>
        <div className="col-3 d-none d-md-block pt-5 ">
          <img src={require("../../assets/image/image 60.png")} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
    {/* <div className="value">
      <div className="title"><span>Core Values</span> of Andorean</div>
      <div className="text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-8 col-12">
          <div className="item">
            <div className="item-inner">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 48.png")} alt="" />
              </div>
              <div className="item-inner-text">
                Ceaseless innovation and creativity
              </div>
            </div>
          </div>
          <div className="item">
            <div className="item-inner">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 51.png")} alt="" />
              </div>
              <div className="item-inner-text">
                Industrial solutions propelled by IT applications
              </div>
            </div>
          </div>
          <div className="item">
            <div className="item-inner">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 64.png")} alt="" />
              </div>
              <div className="item-inner-text">
                Strength from a diverse industry experts
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={require("../../assets/image/image 63.png")} alt="" className="bg" />
    </div> */}
    <div className="vision">
      <div className="box">
        <div className="box-inner">
          <div className="box-inner-content">
            <div className="title"><span>Our vision</span> and What we do</div>
            <div className="text">
            Our goal is to facilitate the sustainable growth target by connecting and organizing multi source of information from variety of technologies and allow the data into meaningful action and enable the big decision making faster than ever.
            </div>
            <div className="video-wrapper">
              <img src={require("../../assets/image/Rectangle 120.jpg")} alt="" />
              <i className="fa fa-play"></i>
              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <img src={require("../../assets/image/image 5.png")} alt="" className="bg" />
      <img src={require("../../assets/image/image 6.png")} alt="" className="bg" />
    </div>
    <div className="case">
     <div className="title">Our use cases</div>
      <div className="container position-relative">
        <div className="news-slider">
          <div className="news-slider__wrp swiper-wrapper">
            <div className="news-slider__item swiper-slide">
              <a href="/mapper" className="news__item first">
                <div className="news__title">Mapper</div>
                    <a href="/mapper" className="news__button ibutton">View</a>
                
                {/* <a href="#" className="news__button ibutton">View</a> */}
              </a>
            </div>
            <div className="news-slider__item swiper-slide">
              <a href="/tag" className="news__item second">
                <div className="news__title">Tag</div>
                <a href="/tag" className="news__button ibutton">View</a>
              </a>
            </div>
            <div className="news-slider__item swiper-slide">
              <a href="/tag" className="news__item third">
                <div className="news__title">Tag</div>
                <a href="/tag" className="news__button ibutton">View</a>
              </a>
            </div>
            <div className="news-slider__item swiper-slide">
              <a href="/tag" className="news__item fourth">
                <div className="news__title">Tag</div>
                <a href="/tag" className="news__button ibutton">View</a>
              </a>
            </div>
            <div className="news-slider__item swiper-slide">
              <a href="/platform" className="news__item fifth">
                {/* <div className="news__title">PLATFORM</div> */}
                <div className="news__title">3D VISUALIZATION</div>

                <a href="/platform" className="news__button ibutton">View</a>
              </a>
            </div>
          </div>
          <div className="news-slider__arrows">
            <button className="news-slider__arrow news-slider-prev">
              <span className="icon-font">
                <i className="fa fa-chevron-left"></i>
              </span>
            </button>
            <button className="news-slider__arrow news-slider-next">
              <span className="icon-font">
                <i className="fa fa-chevron-right"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
   <DarklearnMore/>
   <Footer/>
    </div>
  )
}
