import React from 'react'
// import css from './style.module.css'
export default function Highlights() {
  return (
  <div className="highlights">
    <div className="row w-80 mx-0 mb-4">
      <div className="d-flex align-items-center"> 
        <div className="title text-center">
          Andorean <span className="fw-bold">Highlights</span>
        </div>
      </div>
      <div className="col d-flex align-items-center p-0">
        <div className="line"></div>
      </div>
    </div>
    {/* style="padding-bottom: 3.4vw" */}
    <div className="row w-80 mx-0 d-flex flex-column flex-md-row">
      {/* style="width: max-content" */}
      <div  className="col d-flex align-items-center sub-title">
        Bringing the future of your enterprise from connected systems
      </div>
      <div
        className="col-md-2 col-12 d-flex justify-content-center justify-content-md-end align-self-center p-0 pt-3 pt-md-0">
        <a href="/" className="button button-primary text-dark">See all</a>
      </div>
    </div>
    <div className="container position-relative">
      <div className="news-slider">
        <div className="news-slider__wrp swiper-wrapper">
          <div className="news-slider__item swiper-slide">
            <a href="#" className="news__item first">
              <div className="news__tag">
                <span>Events</span>
              </div>
              <div className="news__title">Dev Summit 2022</div>
              <p className="news__txt">March 19th, 2022</p>
            </a>
          </div>
          <div className="news-slider__item swiper-slide">
            <a href="#" className="news__item second">
              <div className="news__tag">
                <span>News</span>
              </div>
              {/* style="font-size: 22px" */}
              <div className="news__title">
                What is Digital Twin?
              </div>
              <p className="news__txt">January 13th, 2022</p>
            </a>
          </div>
          <div className="news-slider__item swiper-slide">
            <a href="#" className="news__item third">
              <div className="news__tag">
                <span>News</span>
              </div>
              {/* style="font-size: 22px" */}
              <div className="news__title" >
                What is Digital Twin?
              </div>
              <p className="news__txt">January 14th, 2022</p>
            </a>
          </div>
          <div className="news-slider__item swiper-slide">
            <a href="#" className="news__item fourth">
              <div className="news__tag">
                <span>News</span>
              </div>
              {/* style="font-size: 22px */}
              <div className="news__title" >
                What is Digital Twin?
              </div>
              <p className="news__txt">January 15th, 2022</p>
            </a>
          </div>
          <div className="news-slider__item swiper-slide">
            <a href="#" className="news__item fifth">
              <div className="news__tag">
                <span>Employee Story</span>
              </div>
              <div className="news__title">Tuguldur Tugsuu</div>
              <p className="news__txt">Hardware Engineer</p>
            </a>
          </div>
        </div>
        <div className="news-slider__arrows">
          <button className="news-slider__arrow news-slider-prev">
            <span className="icon-font">
              <i className="fa fa-chevron-left"></i>
            </span>
          </button>
          <button className="news-slider__arrow news-slider-next">
            <span className="icon-font">
              <i className="fa fa-chevron-right"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}
