import React from 'react';
import DarkLearnMore from '../../components/DarkLearnMore';
import Highlights from '../../components/Highlights';
import {Link} from "react-router-dom"
import DarkNavbar from '../../components/DarkNavbar';
import Footer from '../../components/Footer';
// import bgImage from "../../assets/video/Andorean.mp4"
function Home() {

  return (
  <div>
   <DarkNavbar/>
  <div className="main-container" > 
    {/* <video className='App' autoPlay loop muted>
    <source src={bgImage} type="video/mp4"/>
    </video> */}
    <div className="hero">
      <div>Infinite creativity</div>
      <div>on a <span>boundless platform</span></div>
    </div>
    <a href="/contact_us" className="button button-def  mt-5">Advance</a>
  </div>
  <div className="technologies">
    <div className="container-fluid h-100 d-flex align-items-center">
      <div className="row px-3 m-0 justify-content-center">
        <div
          className="col-lg-5 col-md-6 col-12 pe-md-5 pb-5 pb-md-0 me-lg-5 d-flex flex-column justify-content-start align-items-center">
          <div className="title w-100">
            <div>
              Andorean technologies to elevate

            </div>
            <div>
               your business potential to new heights

            </div>
          </div>
          <div className="text">
          Andorean delivers organizations around the globe to align their operations in real-time.
           The future generation of enterprises will be propelled by an AI-powered digital twin platform capable of modeling scenarios and developing unique solutions from massive data of IoT sensors to infuse organizations with novel ideas.
          </div>
          <div className="line"></div>
          <a href="/platform" className="button button-primary text-dark align-self-center align-self-md-start">Learn more</a>
        </div>
        <div className="col-lg-4 col-md-6 col-12 ps-lg-5 pe-lg-0 px-5 d-flex align-items-center">
          {/* <div className="card mx-auto"> */}
            <img src={require("../../assets/image/Group32.png")} alt=""  className='img-fluid'/>
          {/* </div> */}
        </div>
      </div>
    </div>
    <img className="bg bg__1" src={require("../../assets/image/image31.png")} alt="" />
    <img className="bg bg__2" src={require("../../assets/image/image32.png")} alt="" />
    <img className="bg bg__3" src={require("../../assets/image/image33.png")} alt="" />
  </div>
  <div className="industeries">
    <div className="title">Industries</div>
    <div className="sub-title px-4 px-lg-0">
    Andorean technology connects entities and tranforms physical object to the digital world across various industries, digitizing from a minuscule pebble to the concrete jungle.
    </div>
    <div className="row w-85 justify-content-center">
      <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10 col-12 ps-xl-3 pe-xl-4 p-lg-3 py-4">
        <div className="box-container">
          <div className="box">
            <div className="text">Mining and Quarries</div>
            <a href="/mining" className="ibutton">View</a>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10 col-12 px-xl-4 p-lg-3 py-4">
        <div className="box-container">
          <div className="box">
            <div className="text">Smart city and Infustructure</div>
            <a href="/construction" className="ibutton">View</a>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10 col-12 ps-xl-4 pe-xl-3 p-lg-3 py-4">
        <div className="box-container">
          <div className="box">
            <div className="text">Construction and Surveying</div>
            <a href="#" className="ibutton">View</a>
          </div>
        </div>
      </div>
    </div>
    </div>
  <div className="partners">
    <h1 className="title">Our Partners</h1>
    <p className="text">
      Andorean embarked on a community-driven mission with a diverse range of
      aspiring businesses and academic institutions to conduct exclusive
      research and develop cutting-edge solutions to enable companies to
      thrive in the digital era.
    </p>
    <div className="grid">
      <div className="grid-cards">
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/ondo.png")} alt="ondo" />
          <p>Mongolia’s 5G network provider and mobile operator</p>
          </div>
        </div>
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/digital.png")} alt="digital" />
          <p>A disrupting start-up company developing “Guyuk” metaverse</p>
          </div>
        </div>
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/fibo.png")} alt="fibo" />
          <p>The AWS cloud computing distributor in APAC region</p>
          </div>
        </div>
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/mulb.png")} alt="mulb" />
          <p>AI/ML organization</p>
          </div>
        </div>
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/nat.png")} alt="nat" />
          <p>National University of Mongolia Department of Applied Science</p>
          </div>
        </div>
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/must.png")} alt="must" />
          <p>
            Mongolian University of Science and Technology School of Geology
            and Mining Engineering
          </p>
          </div>
        </div>
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/geo.png")} alt="geo" />
          <p>Mongolian Geo-information focused organization</p>
          </div>
        </div>
        <div className="grid-cards-inner">
          <div className='grid-cards-inner-border'>
          <img src={require("../../assets/image/circle.png")} alt="circle" />
          <p>
            Public Lab Mongolia - Local civil society organization on the
            ground
          </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="marketplace">
    <div className="container h-100 d-flex align-items-md-center">
      <div className="row">
        <div
        className="col d-flex flex-column align-items-center align-items-md-start justify-content-around justify-content-md-start">
          <div className="img-container">
            <img src={require("../../assets/image/colored.png")} alt="color" />
            <img src={require("../../assets/image/MARKETPLACE.png")} alt="ANDOREAN_MARKETPLACE" />
          </div>
          <p>
          The Andorean marketplace will digitize procurement duties such as searching for available products and services, cost comparison, benchmarking and purchasing decisions, and streamlining product integration by introducing experts into the marketplace.

          </p>
          <a className="button button-primary-reverse" href="https://marketplace.andorean.com/" target={'_blank'}>View Marketplace</a>
        </div>
        <div className="col-md-4 col-lg-5 col-xl-7 d-none d-md-block"></div>
      </div>
    </div>
    {/* <img className="bg d-none d-md-block" src={require("../../assets/image/page_photo1.png")} alt="" /> */}
  </div>
  <DarkLearnMore/>
  <Highlights/>
  <Footer/>
  </div>  
  );
}
export default Home;
