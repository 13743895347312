import React from 'react'
import DarklearnMore from '../../components/DarkLearnMore'
import Footer from '../../components/Footer'
import LightlearnMore from '../../components/LightLearnMore'
import LightNavbar from '../../components/LigthNavbar'

export default function Apply() {
  return (
    <div className="apply">
        <LightNavbar/>
      <div className="main-container">
        <div className="title-wrapper">
          <div className="title">Embedded Hardware Engineer</div>
        </div>
        <div className="row">
          <div className="col-lg-7 col-12">
            <div className="sub-title">About the role</div>
            <div className="text">
              Embedded Hardware Engineer will be a key member of the hardware team
              and the next generation embedded technology hardware development for
              Andorean's Digital Twin product. An Embedded Hardware Engineer
              expertly selects appropriate sensors, and electronic components to
              create an integrated system in custom-designed PCBs and drive the
              development of IoT, hardware systems that serve as a part of
              hardware products.
            </div>
            <div className="sub-title">Responsibilities</div>
            <ul>
              <li>Develop of hardware from conception through prototyping</li>
              <li>
                Work in interdisciplinary R&D teams to distill technical tasks out
                of the end-user expectations.
              </li>
              <li>
                Design proof-of-concept module developments by selecting circuit
                components and creating board layouts. Ensure that the proposed
                solution fits the embedded system’s requirements.
              </li>
              <li>
                Refine electrical component designs with scalability in mind while
                delivering features and performance.
              </li>
              <li>
                Prepare BOM tables and work with the sourcing team to prepare
                scaled production plans.
              </li>
            </ul>
            <div className="sub-title">Requirements</div>
            <ul>
              <li>
                Bachelor/Master’s degree in electrical engineering, engineering
                physics, physics, or equivalent experiences
              </li>
              <li>
                2+ years of industry experience as a hardware design engineer, or
                embedded system design engineer
              </li>
              <li>
                Domain knowledge in outdoor electronic product development is a
                plus
              </li>
              <li>
                Domain knowledge in GPS and UWB technology development is a plus
              </li>
              <li>
                Excellent problem-solving skills, thoroughness, attention to
                detail
              </li>
            </ul>
            <div className="sub-title secondary">Bonus</div>
            <ul>
              <li>Hardware system testing</li>
              <li>Experience with C++, Python, and VHDL is highly desired</li>
              <li>Proficiency with CAD tool is a plus</li>
              <li>Demonstrated experience in managing project schedule</li>
            </ul>
          </div>
          <div className="col-lg-5 col-12 mb-5 mb-lg-0 d-flex justify-content-center">
            <div className="box">
              <div className="d-flex justify-content-between">
                <a href="#" className="button button-primary text-dark">Apply Now</a>
                <a href="#" className="button button-primary-blue text-dark">View on Nito</a>
              </div>
              <div className="box-title"><span>Similar</span> Jobs</div>
              <div>
                <div className="line"></div>
                <div className="box-text">Hardware Engineer</div>
                <div className="d-flex">
                  <div className="box-text-sm">Full-Time</div>
                  <div className="box-text-sm">Ulaanbaatar, Mongolia</div>
                </div>
                <a className="box-link">View Job</a>
              </div>
              <div>
                <div className="line"></div>
                <div className="box-text">Front-End Developer</div>
                <div className="d-flex">
                  <div className="box-text-sm">Full-Time</div>
                  <div className="box-text-sm">Ulaanbaatar, Mongolia</div>
                </div>
                <a href="#" className="box-link">View Job</a>
              </div>
              <div>
                <div className="line"></div>
                <div className="box-text">Back-End Engineer</div>
                <div className="d-flex">
                  <div className="box-text-sm">Full-Time</div>
                  <div className="box-text-sm">Ulaanbaatar, Mongolia</div>
                </div>
                <a href="#" className="box-link">View Job</a>
              </div>
              <div>
                <div className="line"></div>
                <div className="box-text">Marketing Manager</div> 
                <div className="d-flex">
                  <div className="box-text-sm">Full-Time</div>
                  <div className="box-text-sm">Ulaanbaatar, Mongolia</div>
                </div>
                <a href="#" className="box-link">View Job</a>
              </div>
              <div>
                <div className="line"></div>
                <div className="box-text">Business Analyst</div>
                <div className="d-flex">
                  <div className="box-text-sm">Full-Time</div>
                  <div className="box-text-sm">Ulaanbaatar, Mongolia</div>
                </div>
                <a href="#" className="box-link">View Job</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="resume">
        <div className="title">Apply Now</div>
        <div className="box">
          <div className="box-title">All we need is your <b>Resume/CV.</b></div>
          <div className="dropbox">
            <img src="./assests/img/image 19.png" alt="" />
            <div className="dropbox-text">Drag or click here to upload your CV</div>
            <div className="dropbox-text-sm">Supported files: pdf, doc</div>
          </div>
          <div className="item">
            <span>my_cv.pdf</span>
            <i className="fa fa-times text-danger"></i>
          </div>
          <div className="line"></div>
          <div className="col-12 d-flex justify-content-center">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="gridCheck" />
              <label className="form-check-label" for="gridCheck">
                Subscribe for updates and emails from Andorean.
              </label>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <button type="submit" className="button button-primary text-dark">
              Submit
            </button>
          </div>
        </div>
      </div>
     <DarklearnMore/>
     <Footer/>
    </div>
  )
}
