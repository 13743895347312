import React from 'react'

export default function DarklearnMore() {
  return (
    <div className="learnmore">
    <div className="row">
      <div className="col">
        <div className="title">
          <div>Learn more</div>
          <div>about our solutions</div>
        </div>
        <div className="row w-75 mx-auto">
          <div className="col">
            <div className="item">
              <div className="item-img">
                <img src={require("../../assets/image/image 46.png")} alt="" />
              </div>
              <div className="item-text">Speak with solution specialists</div>
              <a className="button button-light" href="/contact_us">Contact Us</a>
            </div>
          </div>
          <div className="col">
            <div className="item">
              <div className="item-img">
                <img src={require("../../assets/image/image 47.png")} alt="" />
              </div>
              <div className="item-text">Request and Start Demo</div>
              <a className="button button-light" href="/contact_us">Get Started</a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-7 d-none d-lg-block">
        {/* <img></img> */}
    {/* <img className="bg bg__1" src={require("../../assets/image/image31.png")} alt="" /> */}
      </div>
    </div>
  </div>
  )
}
