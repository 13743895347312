import React from 'react'
import LightlearnMore from '../../components/LightLearnMore'
// import LightNavbar from '../../components/LigthNavbar'
import DarkNavbar from '../../components/DarkNavbar'
import css from "./style.module.css"
import Footer from '../../components/Footer'
export default function Platform() {
  return (
<div className="platform">
  <DarkNavbar/>
    <div className="main-container">
      <div className="wrapper p-3">
        <div className="hero text-center text-md-start">
          What is <span>Andorean Visualization Platform?</span>
        </div>
        <div className="text">
        Andorean Visualization Platform is an integrated cloud-based visualization and analytics platform to turn point clouds, LiDAR data, and 3D models of the terrain or any ground into detail-infused and interactive 3D and 2D models. A complete Visualization Platform enables users to seamlessly integrate geospatial features into their cloud applications, enterprise software, and services.
        </div>
        <a href="/" className="button button-light">See Use Cases</a>
      </div>
      <div className="row w-100 flex-column flex-sm-row">
        <div
          className="col pe-4 d-flex justify-content-center justify-content-sm-end"
        >
          <a href="/" className="btnn">
            <span>3D Visualization</span>
          </a>
        </div>
        <div
          className="col ps-4 d-flex flex-column align-items-center align-items-sm-start my-5 my-sm-0"
        >
          <h5 className="sub-title">Coming soon</h5>
          <div id="disabled">Data Management Platform</div>
        </div>
      </div>
    </div>
    {/* <div className="feature-container">
      <div className="grid">
        <div className="sub-container-1">
          <h1 className="sub-title">
            3D Visualization <span>Architecture</span>
          </h1>
          <p className="sub-description">
            3D Visualization Platform designated to map and represent the scale
            of real-world objects and areas in greater detail and in a greater
            area. In a wide variety of diverse disciplines, 3D models aid in
            design and research. For instance, the 3D model of the site provides
            a perceptual understanding of the site's condition and helps
            stakeholders and site managers have a more realistic and reasonable
            knowledge of the project's current stage and planning.
          </p>
          <a className="sub-btn">Try for free</a>
        </div>
        <div className="sub-container-2">
          <div className="margin">
            <h3 className="scene-title">Featured scenes</h3>
            <div className="image-card">
              <img src={require('../../assets/image/uul.png')} alt="uul" />
              <div className="border">
                <p className="border-title">Baganuur, Mongolia</p>
              </div>
            </div>
            <div className="image-card">
              <div className="border">
                <p className="border-title">Bor Undur, Mongolia</p>
              </div>
              <img src={require('../../assets/image/uul.png')} alt="uul" />
            </div>
          </div>
        </div>
      </div>
    </div> */}
    {/* <div className="architecture">
      <div className="title">Geospatial <span>Architecture</span></div>
      <div className="row justify-content-around">
        <div className="col-md-5 col-12 d-flex flex-column align-items-center">
          <div className="img-wrapper">
            <img src={require("../../assets/image/architecture.jpg")} alt="" />
          </div>
          <div className="colorful-text"><span>Full model display 4k</span></div>
        </div>
        <div
          className="col-md-6 col-12 d-flex flex-column align-items-center align-items-md-start"
        >
          <div className="text text-center text-md-start">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
          <a href="#" className="button button-primary text-dark mb-5"
            >Try for free</a
          >
        </div>
      </div>
      <img src={require("../../assets/image/img6.png")} alt="" className="bg" />
      <img src={require("../../assets/image/image 6.png")} alt="" className="bg" />
    </div> */}
    <div className="data">
      <div className="row mb-5 mb-md-0 justify-content-center">
        <div
          className="col-md-5 col-xl-4 pe-md-5 col-12 d-flex flex-column align-items-center"
        >
          <div className="title">
            Unprecedented spatial <span>big data into action</span>
          </div>
          <div className="item w-85">
            <div className="item-inner">
              <div className="row">
                <div className="logo">
                  <img src={require("../../assets/image/image 52.png")} alt="" />
                </div>
                <div className="col">
                  <div className="item-inner-text">3D OBJECTS</div>
                </div>
              </div>
              <div className="row">
                <div className="logo">
                  <img src={require("../../assets/image/image 17.png")} alt="" />
                </div>
                <div className="col">
                  <div className="item-inner-text">3D TILES</div>
                </div>
              </div>
              <div className="row">
                <div className="logo">
                  <img src={require("../../assets/image/image 15.png")} alt="" />
                </div>
                <div className="col">
                  <div className="item-inner-text">BUILT-IN TOOLS</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 d-none d-md-flex align-items-center mt-5">
        <img src={require("../../assets/image/image 55.png")} alt="" className="img-fluid" />
        </div>
      </div>
      <div className='bg'><img src={require("../../assets/image/image 63.png")}/></div>
    </div>
    <div className="value">
      <div className="row pb-0">
        <div
          className="col-md-3 col-12 d-flex justify-content-center align-items-center"
        >
          <div className="title d-none d-md-block">VALUES</div>
          <div className="title title-sm d-block d-md-none">VALUES</div>
        </div>
        <div className="col">
          <div className="items">
            <div className="row pt-0">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 2.png")} alt="" />
              </div>
              <div className="col">
                <div className="item-title">
                Less information loss,more coherency 
                </div>
                <div className="item-text">
                A wide range of GIS and environmental data to generate 3D models. Hence, they contain complete and various information about the project site.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 3.png")} alt="" />
              </div>
              <div className="col">
                <div className="item-title">Data-driven decision making</div>
                <div className="item-text">
                GIS and environmental characteristic data is critical for predictive analytics and scenario simulations. Analytics dashboards enables users to assess the current and predicted states
                </div>
              </div>
            </div>
            <div className="row">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 4.png")} alt="" />
              </div>
              <div className="col">
                <div className="item-title">Environment and sustainability</div>
                <div className="item-text">
                Aerial imageries can be an integral part of examining the environmental changes of the habitat and identifying the root cause of the pollution or damage.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={require("../../assets/image/image 5.png")} alt="" className="bg" />
      <img src={require("../../assets/image/image 5.png")} alt="" className="bg" />
      <img src={require("../../assets/image/image 6.png")} alt="" className="bg" />
      
    </div>
    {/* <div className="geospatial">
      <div className="title"><span>Geospatial</span> on Andorean Platform</div>
      <div className="row justify-content-center">
        <div className="col-md-8 col-10 offset-md-3">
          <div className="card">
            <div className="card-inner">
              <div className="row">
                <div className="col-4 d-none d-md-flex align-items-center">
                  <img
                    src={require("../../assets/image/platform_laptop 1.png")}
                    alt=""
                    className="off-img"
                  />
                </div>
                <div className="col">
                  <div
                    className="item d-flex flex-column align-items-center align-items-md-start"
                  >
                    <img
                      src={require("../../assets/image/image 17.png")}
                      alt=""
                      className="item-img"
                    />
                    <div className="text text-center text-md-start">
                      3D Geospatial maps and models represent the scale of
                      real-world objects and areas in greater detail by adding a
                      third (Z) dimension. In a wide variety of diverse
                      disciplines, 3D models aid in design and research.
                    </div>
                  </div>
                  <div
                    className="item d-flex flex-column align-items-center align-items-md-start"
                  >
                    <img
                      src={require("../../assets/image/image 9.png")}
                      alt=""
                      className="item-img"
                    />
                    <div className="text text-center text-md-start">
                      For instance, the 3D model of the site provides a
                      perceptual understanding of the site's condition and helps
                      stakeholders and site managers have a more realistic and
                      reasonable knowledge of the project's current stage and
                      planning.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={require("../../assets/image/image 74.png")} alt="" className="bg" />
    </div> */}
    <div className="keyfeatures">
      <div className="title text-center text-md-start">
        <span>Key Features</span> of Andorean Visualization Platform
      </div>
      <div className="row pb-5">
        <div className="sub-title">3D Geospatial</div>
        <div className="col-md-6 col d-flex align-items-center">
          <div className="line"></div>
        </div>
      </div>
      <div className="row m-0">   
        <div className="col">
          <div className="item">
            <img src={require("../../assets/image/image 8.png")} alt="" className="item-img" />
            <div className="item-text">
            Low  code, high-performance 3D terrain engine with precision terrain mapping
            </div>
          </div>
          <div className="item">
            <img src={require("../../assets/image/image 9.png")} alt="" className="item-img" />
            <div className="item-text">
            Clipping functionality to replace the super resolution model wherever required
            </div>
          </div>
          <div className="item">
            <img src={require("../../assets/image/image 10.png")} alt="" className="item-img" />
            <div className="item-text">
            Processed Satellite image cover
            </div>
          </div>
        </div>
        <div className="col">
          <div className="item">
            <img src={require("../../assets/image/image 50.png")} alt="" className="item-img" />
            <div className="item-text">
            Built-in measurement and spatial analytical tools supporting standard systems.
            </div>
          </div>
          <div className="item">
            <img src={require("../../assets/image/image 12.png")} alt="" className="item-img" />
            <div className="item-text">
            Supported and integrated seamlessly with open API’s and various Engineering software
            </div>
          </div>
          <div className="item">
            <img src={require("../../assets/image/image 52.png")} alt="" className="item-img" />
            <div className="item-text">
            Compatibility of geospatial data, 3D models, BIM in a wide range of scales and formats
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="remote">
      <div className="row">
        <div className="col">
            {/* style="position: relative; top: 131px" */}
          <div className={css.Remote}>
            <div className="title">2D Data on 3D Visualization</div>
            <div className="text">
            2D Geospatial of the Andorean Platform processes significant amounts of GIS data collected from environmental sensors and aerial or satellite images to create high-resolution 2D map-dashboards and visualizations. On 2D mapping, a particular region's essential geographical and environmental characteristics, such as topography, wind flow, drought, and deforestation can be continously visualized.
            </div>
          </div>
          <div className='off-img'>
            <img src={require("../../assets/image/platform_laptop 1.png")}/>
          </div>
        </div>
        <div className="col">
          <div className="box-wrapper d-flex flex-column d-sm-grid">
            <div className="box">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 15.png")} alt="" />
              </div>
              <div className="box-title">1</div>
              <div className="box-text">
                Distinct details and various elements on a single large-scale
                map-dashboard
              </div>
            </div>
            <div className="box">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 16.png")} alt="" />
              </div>
              <div className="box-title">2</div>
              <div className="box-text">
                Combination of multisource geospatial data, including satellite
                and drone images
              </div>
            </div>
            <div className="box">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 17.png")} alt="" />
              </div>
              <div className="box-title">3</div>
              <div className="box-text">
                Interactive scientific tools to uncover patterns, predict
                outcomes, and collect information.
              </div>
            </div>
            <div className="box">
              <div className="img-wrapper">
                <img src={require("../../assets/image/image 18.png")} alt="" />
              </div>
              <div className="box-title">4</div>
              <div className="box-text">
                Globalize the discoveries and solutions and collaborate easily
                with industry specialists.
              </div>
            </div>
            <div className="d-none d-sm-block line line-1"></div>
            <div className="d-none d-sm-block line line-2"></div>
            <div className="d-none d-sm-block line-h line-h-1"></div>
            <div className="d-none d-sm-block line-h line-h-2"></div>
          </div>
        </div>
      </div>
      <div className="bg">
        <img src={require("../../assets/image/image 63.png")}/>
      </div>
    </div>
    {/* <div class="case">
    <div class="box">
      <div class="title">Case Study</div>
      <div class="sub-title"><span>Tuv Aimag Region</span> Sentinel Hub
        Data Visualization</div>
    </div>
    <ul class="text">
      <li>lorem ipsum empty text dfgjdigndfgdfgd
      </li>
      <li>lorem ipsum empty text dfgjdigndfgdfgd
      </li>
    </ul>
    <a href="" class="button button-primary">Read Case Study</a>    
  </div> */}
    <div className="elaborate">
      <div className="row justify-content-around">
        <div className="col-lg-5 col-12 col-md-6 mb-4 mb-md-0">
          <div className="box">
            <div className="box-inner">
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 21.png")} alt="" />
                </div>
                <div className="item-text">
                  The continual flow of multi-source geospatial data, captured
                  by sensors and station gateways (e.g., Andorean Station),
                  processed to Open data cube
                </div>
              </div>
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 26.png")} alt="" />
                </div>
                <div className="item-text">
                  A high-end 2D model, maps generated from big GIS data and
                  satellite images on the Open data cube or cloud employing
                  tile-building algorithms.
                </div>
              </div>
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 25.png")} alt="" />
                </div>
                <div className="item-text">
                  Interpreters can explicate the GIS data, 2D models, satellite
                  images on map-dashboard and integrate with analytical tools of
                  their choice.
                </div>
              </div>
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 24.png")} alt="" />
                </div>
                <div className="item-text">
                  Consequently, they can place their explicated images on the
                  Andorean Marketplace and easily share and cooperate with
                  clients on a single platform.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col d-flex flex-column align-items-center">
          <div className="title text-center text-md-start">
            <span>How are images elaborated in</span> 2D visualization on
            Andorean Platform?
          </div>
          <div className="box-sm">
            <div className="box-inner">
              <div className="row">
                <div
                  className="col d-flex flex-column align-items-center align-items-md-start"
                >
                  <div className="logo">
                    <img src={require("../../assets/image/image 7.png")} alt="" />
                  </div>
                  <div className="box-title text-center text-md-start">
                    ANDOREAN <span>HUB</span>
                  </div>
                  <div className="box-text text-center text-md-start">
                    Deploy reliable and scalable IoT sensor networks in harsh
                    environments.
                  </div>
                  <a href="#" className="button button-light">Learn more</a>
                </div>
                <div className="col position-relative d-none d-lg-block">
                  <img src={require("../../assets/image/STTT 1.png")} alt="" className="big-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LightlearnMore/>
    <Footer/>
  </div>
  )
}
