import React from 'react'
import {NavLink} from "react-router-dom"
import { useState } from "react"
export default function LightNavbar() {
  const[close, setClose]= useState(false);
  const HandlerClick=()=>{
    console.log("colse",close);
    setClose(!close); 
}
  return (
    // <nav  className="navbar navbar-expand-lg nav-light fixed-top">
    <nav  className="navbar navbar-expand-lg nav-light fixed-top">
    <div className="container-fluid me-3">
     <a className="navbar-brand" href="/">
       <div className="logo">
         <img alt="Logo" src={require("../../assets/logo/logoWhite.png")} />
       </div>
       <div className="logo">
         <img alt="Logo" src={require("../../assets/logo/logoBlack.png")} />
       </div>
       <div className="logo-sm d-flex d-sm-none">
         <img className="ms-3" alt="Logo" src={require("../../assets/image/colored.png")} />
       </div>
     </a>
     { close ? 
       <div className="collapse navbar-collapse justify-content-end show "  id="navbarNav">
        <ul className="navbar-nav align-items-lg-center">
          <li className="nav-item dropdown">
           <a className="nav-link" aria-current="page" >Platform</a>
             <div className="dropdown-content">
               <div className="row">  
                <div className="col d-flex flex-column">
                  <h3 className="title">Platform</h3>
                  <a href="/platform">Visualization Platform</a>
                  <h4 className="sub-title">Coming soon</h4>
                  <div id="disabled">Data Management</div>
                </div>
                <div className="col-5 d-flex flex-column">
                  <h3 className="title">Solutions</h3>
                  <a href="/mapper">Andorean Mapper</a>
                  <a href="/tag">Andorean Tag</a>
                  <h4 className="sub-title">Coming soon</h4>
                  <div id="disabled">Andorean Process Tracker</div>
                </div>
                <div className="col d-flex flex-column">
                  <h3 className="title">Industries</h3>
                  <a href="/mining">Mining</a> 
                   <a href="/construction"> Construction</a>
                </div>
              </div>
            </div> 
          </li> 
          <li className="nav-item dropdown">
           <a className="nav-link">Company</a>
            <div className="dropdown-content">
              <div className="row">
                <div className="col-5 d-flex flex-column">
                  <h3 className="title">About Us</h3> 
                  <a href="/mission">Our Mission</a>
                  <a href="/team">Our Team</a>
                  <a href='/'>Partners</a>
                  {/* <NavLink to="partners">Partners</NavLink> */}
                  <a href="/career">Careers</a>
                  <a href="/contact_us">Contact Us</a>
                </div>
                <div className="col d-flex flex-column justify-content-between align-items-start">
                  <h3 className="title mb-0">Highlights</h3>
                  <div className="card border-0 rounded-0">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src={require("../../assets/image/DSC02.png")} className="img-fluid" />
                      </div>
                      <div className="col-md-7 d-flex align-items-center">
                        <div className="card-body">
                          <h5 className="card-title ">
                            Most recent</h5>
                          <div className="card-text text-white">
                          A panel discussion and 3 presentations were held on the topics of Possessing technology, Integrating with Unity/Unreal engines, Reliving History in VR and How to integrate Metaverse and Real-life.
                          {/* <div className="txt-title">Dev Summit 2022</div>
                             <p className="card-txt">March 19th, 2022</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <a href="/"  className="align-self-end">
                    See all news<i className="fa fa-arrow-right ps-2"></i>
                    </a>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item"> 
            <a className='nav-link' href="https://marketplace.andorean.com/" target={'_blank'}>Marketplace</a>
          </li>
          <li className="nav-item"> 
            <a className="button" href="/login">Go to Portal</a>
          </li>
        </ul>
      </div>: <div className="collapse navbar-collapse justify-content-end  "  id="navbarNav">
        <ul className="navbar-nav align-items-lg-center">
          <li className="nav-item dropdown">
           <a className="nav-link" aria-current="page" >Platform</a>
             <div className="dropdown-content">
               <div className="row">  
                <div className="col d-flex flex-column">
                  <h3 className="title">Platform</h3>
                  <a href="/platform">Visualization Platform</a>
                  <h4 className="sub-title">Coming soon</h4>
                  <div id="disabled">Data Management</div>
                </div>
                <div className="col-5 d-flex flex-column">
                  <h3 className="title">Solutions</h3>
                  <a href="/mapper">Andorean Mapper</a>
                  <a href="/tag">Andorean Tag</a>
                  <h4 className="sub-title">Coming soon</h4>
                  <div id="disabled">Andorean Process Tracker</div>
                </div>
                <div className="col d-flex flex-column">
                  <h3 className="title">Industries</h3>
                  <a href="/mining">Mining</a> 
                   <a href="/construction"> Construction</a>
                </div>
              </div>
            </div> 
          </li> 
          <li className="nav-item dropdown">
           <a className="nav-link">Company</a>
            <div className="dropdown-content">
              <div className="row">
                <div className="col-5 d-flex flex-column">
                  <h3 className="title">About Us</h3> 
                  <a href="/mission">Our Mission</a>
                  <a href="/team">Our Team</a>
                  <a href='/'>Partners</a>
                  {/* <NavLink to="partners">Partners</NavLink> */}
                  <a href="/career">Careers</a>
                  <a href="/contact_us">Contact Us</a>
                </div>
                <div className="col d-flex flex-column justify-content-between align-items-start">
                  <h3 className="title mb-0">Highlights</h3>
                  <div className="card border-0 rounded-0">
                    <div className="row g-0">
                      <div className="col-md-5">
                        <img src={require("../../assets/image/DSC02.png")} className="img-fluid" />
                      </div>
                      <div className="col-md-7 d-flex align-items-center">
                        <div className="card-body">
                          <h5 className="card-title ">
                            Most recent</h5>
                          <div className="card-text text-white">
                          A panel discussion and 3 presentations were held on the topics of Possessing technology, Integrating with Unity/Unreal engines, Reliving History in VR and How to integrate Metaverse and Real-life.
                          {/* <div className="txt-title">Dev Summit 2022</div>
                             <p className="card-txt">March 19th, 2022</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <a href="/"  className="align-self-end">
                    See all news<i className="fa fa-arrow-right ps-2"></i>
                    </a>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item"> 
            <a className='nav-link' href="https://marketplace.andorean.com/" target={'_blank'}>Marketplace</a>
          </li>
          <li className="nav-item"> 
            <a className="button" href="/login">Go to Portal</a>
          </li>
        </ul>
      </div> } 
   </div>
 </nav>
  )
}
