import React from 'react'
import css from "./style.module.css"
export default function Footer() {
  return ( 
     <div className={css.Footer}>
      <div className={css.FooterImg1}></div>
      <div className={css.FooterImg2}></div>
      <div className={css.FooterGrid}>
        <div className={css.FooterFlex}>
          <h1 className={css.FooterTitle}>
          Platform
        </h1>
        <p className={css.FooterDesc}>Visualization Platform</p>
        <p className={css.FooterDesc}>Andorean Mapper</p>
        <p className={css.FooterDesc}>Andorean Tag</p>
        <p className={css.FooterDesc}>Data Management</p>
      
      </div>
        <div className={css.FooterFlex}><h1 className={css.FooterTitle}>
          Industries
        </h1>
        <p className={css.FooterDesc}>Mining</p>
        <p className={css.FooterDesc}>Construction</p>
        <p className={css.FooterDesc}>Smart City</p>
        </div>
        <div className={css.FooterFlex}>
          <h1 className={css.FooterTitle}>About Us</h1>
          <p className={css.FooterDesc}>Our Mission</p>
          <p className={css.FooterDesc}>Our Team</p>
          <p className={css.FooterDesc}>Contact Us</p>
          <p className={css.FooterDesc}>Careers</p></div>
        <div className={css.FooterFlex}>
          <h1 className={css.FooterTitle}>Follow Us</h1>
        <div className={css.IconFlex}>
          <a href="https://www.facebook.com/Andoreantech/" target={"_blank"} className={css.FooterIcon} >
            <img src={require("../../assets/image/Vector.png")} alt="1"/>
            </a>
          <a href="https://www.instagram.com/andoreantech/" target={"_blank"} className={css.FooterIcon}><img src={require('../../assets/image/Vector1.png')} alt="2"/></a>
          <a href="https://www.linkedin.com/company/andorean/mycompany/?viewAsMember=true" target={"_blank"}  className={css.FooterIcon} ><img src={require("../../assets/image/Vector2.png")} alt="3"/></a>
        </div>
        <a href='/'>
           <img className={css.ImageFooter} src={require("../../assets/image/trade.png")}/> 
          </a>       
        </div>
      </div>
      <p className={css.DetailTitle}> © Andorean - all rights reserved</p>
    </div>
  )
}
