import React from 'react'
import DarkNavbar from '../../components/DarkNavbar'
import Footer from '../../components/Footer'
import LightlearnMore from '../../components/LightLearnMore'

export default function Mining() {
  return (
 <div className="mining">
  <DarkNavbar />
 <div className="main-container">
      <div className="wrapper">
        <div className="title">
          <div><span>Mining and Quarries</span></div>
        </div>
        <div className="text">
          An increase in production, efficiency, and cost minimization, while
          simultaneously addressing sustainability concerns and pursuing global
          competitiveness are the main objectives that today's mining industry
          must balance. Additionally, the sector must maintain safety
          regulations while alleviating environmental damage and adhering to
          applicable social, environmental, and other regulatory obligations.
          <div className="mt-3">
            Andorean's industrial solutions for the mining industry
            can potentially aid in ways beyond automation and repaint the road
            for novel applications such as transparency, security, and
            effectiveness.
          </div>
        </div>
      </div>
    </div>
  <div className="explanation">
       <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-xl-6">
          <div className="item">
            <div className="line"></div>
            <div className="check"><i className="fa fa-check"></i></div>
            The resilient and scalable edge computing performs decentralized
            data processing of the massive amount data prior to feeding them
            into the Cloud's Machine Learning and Deep Learning models.
          </div>
        </div>
        <div className="col-12 col-md-8 col-xl-6">
          <div className="item">
            <div className="line"></div>
            <div className="check"><i className="fa fa-check"></i></div>
            Andorean provides compatible integrations with third party gateway
            and sensors, cloud premises, webservice API’s, and engineering
            software of the client’s interest.
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="item">
            <div className="line"></div>
            <div className="check"><i className="fa fa-check"></i></div>
            The dynamic timeline of the 3D model of the mine site and mining
            activity enables a descriptive and prescriptive root cause
            analysis of the particular instance. As a consequence, the platform
            simulates the mining site's projected changes.
          </div>
        </div>
        <div className="col-12 col-md-8 col-lg-6">
          <div className="item">
            <div className="line"></div>
            <div className="check"><i className="fa fa-check"></i></div>
            Project managers can instantly share interactive dashboards, models,
            and maps with the organization's members and stakeholders. The mine
            site's customized and comprehensive report in different units of
            measurement is available for analysis.
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6"></div>
        <div className="col-12 col-md-8 col-lg-6">
          <div className="item">
            {/* style="height: 1.2px" */}
            <div className="line" ></div>
            <div className="check"><i className="fa fa-check"></i></div>
            A single platform for controlling and visualizing the network of
            telemetry and sensor devices with rich server API’s . The analytics
            dashboard helps a user to define relation between the connected
            assets and personnel
          </div>
        </div>
      </div>
      <div className="bg">
        <img src={require("../../assets/image/image 63.png")} alt="" /></div> 
    </div>

     <LightlearnMore/>
     <Footer/>
    </div>
  )
}
