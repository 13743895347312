import React from 'react'
import Footer from '../../components/Footer'
import LightNavbar from '../../components/LigthNavbar'

export default function Login() {
  return (
    <div className='login'>
        <LightNavbar/>
     <div className="main-container px-5 px-md-0">
      <div
        className="col-12 col-md-6 order-2 order-md-1 d-flex justify-content-center"
      >
        <div className="card my-5 my-md-0">
          <div className="card-img">
            <img src={require("../../assets/image/colored.png")}/>
          </div>
          <form className="row g-3 mx-0 flex-column align-items-center">
            <div className="col-12 px-0">
              <label for="email" className="form-label">Email:</label>
              <input type="email" className="form-control" id="email" />
            </div>
            <div className="col-12 px-0">
              <label for="password" className="form-label">Password:</label>
              <input type="password" className="form-control" id="password" />
            </div>
            <a href="/forgotpassword" className="mt-2 align-self-end">Forgot Password?</a>
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" className="button button-primary text-dark">
                Log In
              </button>
            </div>
            <div className="line"></div>
            <button className="google">
                <img src={require("../../assets/image/image 77.png")}/>
                  Sign In with Google
            </button>
          </form>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  )
}
