import React from 'react'

export default function LightlearnMore() {
  return (
    <div className="learn-more">
      <div className="container d-flex flex-column align-items-center px-5">
        <div className="title"><span>Learn more</span> about our solutions</div>
        <div className="row">
          <div className="col">
            <div className="item">
              <div className="item-img">
                <img src={require("../../assets/image/image 28.png")} alt="" />
              </div>
              <div className="item-text">
                Help us understand where you are in your advancement towards
                Andorean solutions
              </div>
            </div>
          </div>
          <div className="col">
            <div className="item">
              <div className="item-img">
                <img src={require("../../assets/image/image 29.png")} alt="" />
              </div>
              <div className="item-text">
                Explore more in-depth solutions and features by talking to an
                expert
              </div>
            </div>
          </div>
        </div>
        <a href="/contact_us" className="button button-def text-dark mb-5">Contact Us</a>
        <img
          src={require("../../assets/image/image 5.png")}
          alt=""
          className="bg d-none d-lg-block"
        />
        <img
          src={require("../../assets/image/image 6.png")}
          alt=""
          className="bg d-none d-lg-block"
        />
      </div>
    </div>
  )
}
