import React from 'react'
import LightLearnMore from '../../components/LightLearnMore'
import DarkNavbar from '../../components/DarkNavbar'
import Footer from '../../components/Footer'

export default function Tag() {
  return (
    <div className="tag">
      <DarkNavbar/>
     <div className="main-container">
       <div className="wrapper d-flex">
        <div
          className="w-100 w-md-60 h-100 d-flex flex-column justify-content-around"
        >
          <div className="hero text-center text-md-start">
            Ultra-Wideband based 
            <span>Andorean Tag</span>
          </div>
          <div className="text">
            Andorean's ultra-wideband-based tag is the essential IoT sensor
            module for personnel and vehicle location tracking. The tag can be
            attached to personnel or asset to pinpoint the location in
            real-time. The real-time information of the location will
            significantly enhance the situation awareness around the industrial
            environment.
          </div>
          <div className="desc">
            Andorean U-Tag sends UWB pulses to the Andorean station, translating
            and transmitting data to the server

            <div className="line d-none d-md-block"></div>
          </div>
        </div>
        <div className="img-wrapper d-none d-md-block">
          <img src={require("../../assets/image/tag1-removebg-preview 1.png")} alt="" />
        </div>
      </div>
    </div>
    <div className="keyfeatures">
      <div className="row">
        <div
          className="col-12 col-lg-7 d-flex flex-column align-items-center position-relative"
        >
          <div className="title">
            <div>Key Features</div>
               of Andorean Tag
          </div>
          <div className="img-wrapper">
            <img
              src={require("../../assets/image/image 62.png")}
              alt=""
              className="img-fluid d-none d-lg-block"
            />
          </div>
        </div>
        <div
          className="col-12 col-lg d-flex justify-content-center justify-content-lg-start"
        >
          <div className="box">
            <div className="box-inner">
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 56.png")} alt="" />
                </div>
                <div className="item-text">
                  With real-time personal location data, industries can monitor
                  safety requirements on-site and assure employee safety,
                  especially in hazardous places.
                </div>
              </div>
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 57.png")} alt="" />
                </div>
                <div className="item-text">
                  Personal Location Tracking (PLT) using UWB will provide
                  real-time location information up to 50 cm.
                </div>
              </div>
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 9.png")} alt="" />
                </div>
                <div className="item-text">
                  Users are able to monitor the status and location of all their
                  equipment and fleets regardless of the GPS denied environment
                  or in the open area.
                </div>
              </div>
              <div className="item">
                <div className="img-wrapper">
                  <img src={require("../../assets/image/image 58.png")} alt="" />
                </div>
                <div className="item-text">
                  Further advancements will focus on transmitting data regarding
                  a person's physical status and can also serve as a warning
                  system in the event of accident.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LightLearnMore/>
    <Footer/>
 </div>
  )
}