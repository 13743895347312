import React from 'react'
import LightNavbar from '../../components/LigthNavbar'

export default function ForgotPass() {
  return (
    <div className='forgotpass'>
        <LightNavbar/>
         <div className="main-container px-5 px-md-0">
      <div
        className="col-12 col-md-6 order-2 order-md-1 d-flex justify-content-center"
      >
        <div className="card my-5 my-md-0">
          <div className="card-img">
            <img src={require("../../assets/image/colored.png")}/>
            {/* <img src="./assests/img/colored.png" alt="" /> */}
          </div>
          <form className="row g-3 mx-0 flex-column align-items-center">
            <div className="text">
              Enter your email and we will send you instructions on how to
              change your password
            </div>
            <div className="col-12 px-0 align-self-start">
              <label for="email" className="form-label">Email:</label>
              <input type="email" className="form-control" id="email" />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" className="button button-primary text-dark">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  )
}
