import React from 'react'
import Footer from '../../components/Footer'
import Highlights from '../../components/Highlights'
import LightNavbar from '../../components/LigthNavbar'
export default function ContactUs() {
  return (
<div className="contact">
    <LightNavbar/>
   <div class="main-container">
      <div class="row mx-0">
        <div
          class="col-12 col-md-6 order-2 order-md-1 d-flex justify-content-center"
        >
          <div class="card my-5 my-md-0">
            <div class="card-title">
              Fill the forum to <b>connect with us.</b>
            </div>
            <form class="row g-3 px-0">
              <div class="col-md-6 px-0 pe-4">
                <label for="fName" class="form-label"
                  >First Name: <span class="text-danger">*</span></label
                >
                <input type="text" class="form-control" id="fName" />
              </div>
              <div class="col-md-6 px-0 pe-4">
                <label for="lName" class="form-label"
                  >Last Name: <span class="text-danger">*</span></label
                >
                <input type="text" class="form-control" id="lName" />
              </div>
              <div class="col-md-6 px-0 pe-4">
                <label for="email" class="form-label"
                  >Email: <span class="text-danger">*</span></label
                >
                <input type="text" class="form-control" id="email" />
              </div>
              <div class="col-md-6 px-0 pe-4">
                <label for="phone" class="form-label"
                  >Phone Number: <span class="text-danger">*</span></label
                >
                <input type="number" class="form-control" id="phone" />
              </div>
              <div class="col-md-6 px-0 pe-4">
                <label for="company" class="form-label"
                  >Company Name: <span class="text-danger">*</span></label
                >
                <input type="text" class="form-control" id="company" />
              </div>
              <div class="col-md-6 px-0 pe-4">
                <label for="inputState" class="form-label"
                  >Country: <span class="text-danger">*</span>
                </label>
                <select id="inputState" class="form-select">
                  <option selected>Select country..</option>
                  <option>...</option>
                </select>
              </div>
              <div class="line"></div>
              <div class="col-12 d-flex justify-content-center">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label class="form-check-label" for="gridCheck">
                    Subscribe for updates and emails from Andorean.
                  </label>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <button type="submit" class="button button-primary text-dark">
                  Submit Request
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          class="col-12 col-md-6 d-flex flex-column align-items-center order-1 order-md-2"
        >
          <div class="title">
            Get started with
            <div>ANDOREAN</div>
          </div>
          <div class="text">
            Acclererate your growth of business with Andorean solutions.
          </div>
          <div class="address">
            <div class="line"></div>
            <b>info@andorean.com</b>
            <div>Sukhbaatar District, Khoroo 1, Olimp 5</div>
            <div>Ayud Tower</div>
            <div>Ulaanbaatar, Mongolia</div>
          </div>
        </div>
      </div>
   </div>
   <Highlights/>
   <Footer/>
    </div>
  )
}