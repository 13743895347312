import React from 'react'
import DarkNavbar from '../../components/DarkNavbar'
import Footer from '../../components/Footer'
import LightlearnMore from '../../components/LightLearnMore'

export default function Mapper() {
  return (
     <div className="mapper"> 
     <DarkNavbar/>
      <div className="main-container">
       <div className="hero">
        Agile and Accurate<span>A.I based geo-data processing</span>
       </div>
      <div className="text">
        Andorean Mapper is a reliable AI-based geospatial data processing
        service for drone photogrammetry and LiDAR data associated with an
        integrated cloud computing solution, offering matchless processing speed
        with incredible accuracy.
      </div>    
      <a href="#" className="button button-light mt-5">See Use Cases</a>
       </div>
     <div className="explanation">
      <div className="title">How Does <span>Andorean Mapper work? </span></div>
      <div className="text">
        With simple steps shown below, leverage the power of Geness for your
        survey data without the need for high-end computers or professionals.
      </div>
      <div className="row">
        <div className="col">
          <div className="item">
            <div className="line"></div>
            <div className="check"><i className="fa fa-check"></i></div>
            Upload the photogrammetry or LiDAR data acquired from the project
            site on Mapper.
          </div>
          <div className="item">
            <div className="line"></div>
            <div className="check"><i className="fa fa-check"></i></div>
            Mapper will generate a complete and detailed geodetic survey report
            of your project’s site
          </div>
          <div className="item">
            {/* style="height: 1.2px" */}
            <div className="line" ></div>
            <div className="check"><i className="fa fa-check"></i></div>
            Access and view a holistic 3D model of your site on multi-user
            Andorean 3D Geospatial anytime and anywhere from your desired device
          </div>
          <div className="item">
            <div className="line"></div>
            <div className="check"><i className="fa fa-check"></i></div>
            Mapper performs data processing, in which DSM, DTM, Orthomosaic,
            Point Cloud, and Model files are generated. The processed data can
            be further sent to the client’s server.
          </div>
        </div>
        <div
          className="col-7 d-none d-md-flex justify-content-center align-items-center"
        >
          <div className="img-wrapper">
            <img className="img-fluid" src={(require("../../assets/image/notebook.png"))} alt="" />
            <div className="circle circle__1"></div>
            <div className="circle circle__2"></div>
            <div className="circle circle__3"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="keyfeatures">
      <div className="title text-center text-md-start mb-5">
        <span>Key Features</span> of Andorean Mapper
      </div>
      <div className="row mx-0 px-5">
        <div
          className="col d-none d-md-flex justify-content-center align-items-center"
        >
          <div className="img-container">
            <img src={require("../../assets/image/keyfeatures.png")} alt="" />
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
        <div className="col d-flex flex-column justify-content-center">
          <div className="item">
            <div className="item-img">
              <img src={(require("../../assets/image/image 48.png"))} alt="" />
            </div>
            <div className="item-text">
              Limitless number of image processing with high data processing
              speed (within 24hr) and incredible accuracy
            </div>
          </div>
          <div className="item">
            <div className="item-img">
              <img src={(require("../../assets/image/image 50.png"))} alt="" />
            </div>
            <div className="item-text">
              Automatic GCP recognition, DTM extraction, color adjustments, and
              precise georeferencing
            </div>
          </div>
          <div className="item">
            <div className="item-img">
              <img src={(require("../../assets/image/image 51.png"))} alt="" />
            </div>
            <div className="item-text">
              Effortless and practical tools to alleviate the burden of survey
              work
            </div>
          </div>
          <div className="item">
            <div className="item-img">
              <img src={(require("../../assets/image/image 52.png"))} alt="" />
            </div>
            <div className="item-text">
              Seamless integrations with Open API’s, and mining and engineering
              software.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="industeries">
      <div className="container position-relative pb-5">
        <div className="title">Industries</div>
        <div className="position-relative">
          <div className="line-wrapper">
            <div className="line"></div>
            <div className="line-h"></div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg p-xl-0">
              <div className="card">
                <div className="card-title">Survey & GIS</div>
                <div className="row">
                  <div className="card-image mb-3 mb-lg-0">
                    <img src={(require("../../assets/image/survey.jpg"))} alt="" />
                  </div>
                  <ul className="card-txt col">
                    <li>Project Planning</li>
                    <li>Cadastrial Survey</li>
                    <li>Drone Survey</li>
                    <li>Construction progress</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="col-12 col-md-6 col-lg p-xl-0">
              <div className="card">
                <div className="card-title">Mining Industry</div>
                <div className="row">
                  <div className="card-image mb-3 mb-lg-0">
                    <img src={(require("../../assets/image/mining industry.jpg"))} alt="" />
                  </div>
                  <ul className="card-txt col">
                    <li>Pit/Dump Management</li>
                    <li>Stockpile volume</li>
                    <li>Before/after analysis</li>
                    <li>Safety Compliance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="line-wrapper">
            <div className="line-h"></div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg p-xl-0">
              <div className="card">
                <div className="card-title">Agriculture & Environment</div>
                <div className="row">
                  <div className="card-image mb-3 mb-lg-0">
                    <img src={(require("../../assets/image/agri.jpg"))} alt="" />
                  </div>
                  <ul className="card-txt col">
                    <li>Vegetation health</li>
                    <li>analysis</li>
                    <li>Damage assessment</li>
                    <li>Population counting</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="col-12 col-md-6 col-lg p-xl-0">
              <div className="card">
                <div className="card-title">Construction Infrastructure</div>
                <div className="row">
                  <div className="card-image mb-3 mb-lg-0">
                    <img src={(require("../../assets/image/const.jpg"))} alt="" />
                  </div>
                  <ul className="card-txt col">
                    <li>Pre-construction</li>
                    <li>Earthwork progress</li>
                    <li>Maintenance Inspection</li>
                    <li>Cut-fill analysis</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text">
          Learn more about how our solutions affect the industries
        </div>
        <a href="/mining" className="button button-light">Learn more</a>
      </div>
    </div>
  <LightlearnMore/>
  <Footer/>
  </div> 
  )
}
