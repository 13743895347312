import React from 'react'
import DarkNavbar from '../../components/DarkNavbar'
import Footer from '../../components/Footer'
import LightlearnMore from '../../components/LightLearnMore'

export default function Construction() {
  return (
    <div className="city" >
   <DarkNavbar/>
      <div className="main-container">
        <div className="wrapper">
        <div className="title">
          <div><span>Smart city and Infrastructure</span></div>
        </div>
        <div className="text">
          Digital Twins and IoT set the foundations of modern Smart Cities
          through clear-cut 3D visualizations fueled by the continuous flow of
          data generated from connected devices. Consequently, urban planners
          and city municipalities foster socioeconomic components of the city
          and maintain sustainable environmental standards by integrating
          real-world 3D geospatial context and interconnected network of
          infrastructure. These practical solutions lead to a new era of urban
          life, characterized by insight-driven public sectors, a secure
          environment, and private-public organization partnerships.
        </div>
      </div>
    </div>
    <div className="boxes">
      <div className="row justify-content-center justify-content-lg-start">
        <div className="col-12 col-md-8 col-xl-6 offset-xl-1">
          <div className="box">
            <div className="box-inner">
              <div className="box-title text-center text-lg-start">
              City Scale geospatial 3D model 
              </div>
              <div className="row flex-column flex-lg-row align-items-center">
                <div className="col">
                  <div className="box-text mb-3 mb-lg-0">
                  Andorean technologies smoothly generate the digital twins of the cities and geospatial context by leveraging high-scale aerial mapping, 3D tile builder algorithms, and robust edge computing systems. As a derivative, urban planners can monitor an entire city's activities and the infrastructure just from their devices. Furthermore, an interactive 3D visualization is a primary tool for conducting extensive social demographic research.
                  </div>
                </div>
                <div className="box-img">
                  <img src={require("../../assets/image/AdobeStock4.jpg")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center justify-content-lg-end">
        <div className="col-12 col-md-8 col-xl-6">
          <div className="box">
            <div className="box-inner">
              <div className="box-title text-center text-lg-start">
                Real-Time sustainability metrics analytics
              </div>
              <div className="row flex-column flex-lg-row align-items-center">
                <div className="col">
                  <div className="box-text mb-3 mb-lg-0">
                  Comprehensive city visualization and real-time data analytics are the essential implements for promoting a healthy quality of life and environmentally sustainable practices. Urban planners intelligently organize urban structure, greenspace and even wastelands on the visualization platform. In addition, researchers can monitor energy and water use, greenhouse gas emissions, and waste production using real-time data from connected infrastructure.
                  </div>
                </div>
                <div className="box-img">
                  <img src={require("../../assets/image/AdobeStock5.jpg")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1 d-none d-xl-block"></div>
      </div>
      <div className="row justify-content-center justify-content-lg-start">
        <div className="col-12 col-md-8 col-xl-6 offset-xl-1">
          <div className="box">
            <div className="box-inner">
              <div className="box-title text-center text-lg-start">
              Connected transportation network
              </div>
              <div className="row flex-column flex-lg-row align-items-center">
                <div className="col">
                  <div className="box-text mb-3 mb-lg-0">
                  Andorean Tags transmit data to a server when a vehicle is engaged in an accident or breaks down, relaying the incident to other drivers. Traffic Engineers benefit significantly from the 3D visualizations by extracting critical insights from traffic simulations, capacity estimates, and transportation mobility monitoring. As a result, connected vehicles reduce traffic delays while boosting driving safety.
                  </div>
                </div>
                <div className="box-img">
                  <img src={require("../../assets/image/IMG80.jpg")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={require("../../assets/image/img74.png")} alt="" className="bg" />
    </div>
    <LightlearnMore/>
    <Footer/>
    </div>
  )
}
