import React from 'react'
import LightLearnMore from '../../components/LightLearnMore'
import DarkNavbar from '../../components/DarkNavbar'
import Footer from '../../components/Footer'

export default function Career() {
  return (
<div className="career">
  <DarkNavbar/>
 <div className="main-container">
    <div className="wrapper">
        <div className="title">
          <div>Grow <span> with us</span> </div>
        </div>
        <div className="text">
        We create inclusive working environment where all team members benefit from multi-learning. By joining our passionate team committed to cutting edge technologies, each team member will gain deep knowledge in ML/AI, 3D scene understanding and large scale infrastructure. See the specific positions listed below. 
        </div>
      </div>
</div>
<div className="intro">
<div className="inner-container">
      <h1 className="title">Current Openings</h1>
      <ul className="toolbar">
        <li><a href="#" className="active-toolbar">View All Listings </a></li>
        <li><a href=""> Full-Time </a></li>
        <li><a href=""> Part-Time </a></li>
        <li><a href=""> Internship </a></li>
      </ul>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <td className="table-body-text">Front-End Devoloper</td>
              <td className="table-second-text">Full-time</td>
              <td className="table-third-text">Ulaanbaatar, Mongolia</td>
              <td className="table-forth-text">1 week ago</td>
              <td><a className="primary-text" href="/apply">Apply</a></td>
            </tr>
            <tr>
              <td className="table-body-text">Marketing Manager</td>
              <td className="table-second-text">Intership</td>
              <td className="table-third-text">Ulaanbaatar, Mongolia</td>
              <td className="table-forth-text"> 1 week ago</td>
              <td><a className="primary-text" href="/apply">Apply</a></td>
            </tr>
            <tr>
              <td className="table-body-text">Business Analyst</td>
              <td className="table-second-text">Full-time</td>
              <td className="table-third-text">Ulaanbaatar, Mongolia</td>
              <td className="table-forth-text">1 week ago</td>
              <td><a className="primary-text" href="/apply">Apply</a></td>
            </tr>
            <tr>
              <td className="table-body-text">Back-End Devoloper</td>
              <td className="table-second-text">Full-time</td>
              <td className="table-third-text">Ulaanbaatar, Mongolia</td>
              <td className="table-forth-text">1 week ago</td>
              <td><a className="primary-text" href="/apply">Apply</a></td>
            </tr>
            <tr>
              <td className="table-body-text">Hardware Engineer</td>
              <td className="table-second-text">Full-time</td>
              <td className="table-third-text">Ulaanbaatar, Mongolia</td>
              <td className="table-forth-text">1 week ago</td>
              <td><a className="primary-text" href="/apply">Apply</a></td>
            </tr>
          </tbody>
          </table>
      </div>
</div>
</div>
<LightLearnMore/>
<Footer/>
</div>
  )
}
