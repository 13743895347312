import React from "react";
import {createRoot} from "react-dom/client";
import "./assets/scss/styles.scss"
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Mapper from "./pages/Mapper";
import Platform from "./pages/Platform";
import Tag from "./pages/Tag";
import Mining from "./pages/Mining"
import Construction from "./pages/Construction"
import Mission from "./pages/Mission"
import Team from "./pages/Team"
import Career from "./pages/Career";
import './loader'
import './navbar'
import './slider'
import ContactUs from "./pages/Contact_Us";
import Apply from "./pages/Apply";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPassword";
const container = document.getElementById('root');
const root = createRoot(container); 
// Scroll();
root.render(
<BrowserRouter>
   <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="contact_us" element={<ContactUs/>}/>
        <Route path="career" element={<Career/>}/>
        <Route path="team" element={<Team/>}/>
        <Route path="mission" element={<Mission/>}/>
        <Route path="construction" element={<Construction/>}/>
        <Route path="mining" element={<Mining/>} /> 
        <Route path="tag" element={<Tag/>} />
        <Route path="mapper" element={<Mapper/>} />
        <Route path="platform" element={<Platform/>} />
        <Route path="apply" element={<Apply/>}/>
        <Route path="login" element={<Login/>}/>        
        <Route path="forgotpassword" element={<ForgotPass/>}/>
</Routes>
</BrowserRouter>);